import { Checkbox, Switch } from "@mui/material"
import React, { useEffect, useState } from "react"

export const CheckboxCell = ({
  id,
  name,
  initialValue,
  onChangeAsync,
  disabled = false,
  variant = "checkbox"
}) => {
  const [value, setValue] = useState(initialValue)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    let mounted = true
    if (mounted) setValue(initialValue)

    return () => {
      mounted = false
    }
  }, [initialValue])

  const handleUpdate = (newValue) => {
    setValue(newValue)
    setLoading(true)
    onChangeAsync(newValue)
      .catch(() => {
        setValue(initialValue)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const Component = variant === "switch" ? Switch : Checkbox

  return (
    <Component
      id={id}
      name={name}
      disabled={loading || disabled}
      checked={value}
      onChange={(e) => handleUpdate(e.target.checked)}
    />
  )
}
